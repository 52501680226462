@import "../variables";
:root.marketplace {
    --bs-primary: #{$m-dark};
    --platform-color: #{$m-dark};
    --platform-color-light: #{$m-light};
    .btn-primary {
        background-color: #{$m-dark};
        border-color: #{$m-light};
        color: #fff;
        &:hover {
            background-color: #{$m-dark};
        }
    }
}
