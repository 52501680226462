.clickable {
    cursor: pointer;
}

.text-right {
    text-align: right;
}

.min-h-100 {
    min-height: 100%;
}

.mw-500 {
    min-width: 500px;
}

.mw-1000 {
    min-width: 850px;
}

.w-70 {
    width: 70% !important;
}

.w-60 {
    width: 60% !important;
}

.w-40 {
    width: 40% !important;
}

.w-30 {
    width: 30% !important;
}

.w-20 {
    width: 20% !important;
}

.w-10 {
    width: 10% !important;
}
