@import "../variables";
:root.gazette {
    --bs-primary: #{$g-dark};
    --bs-primary-hover: darken($g-dark, 10%);
    --platform-color: #{$g-dark};
    --platform-color-light: #{$g-light};
    .btn-primary {
        background-color: #{$g-dark};
        border-color: #{$g-light};
        color: #000;
        &:hover {
            background-color: #{$g-dark};
            color: #000;
        }
    }
}
