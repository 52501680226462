@import "variables";

.header-dropdown {
    position: relative;
    &.active {
        .header-dropdown-selection {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
            z-index: 901;
            color: $secondary;
            // text-shadow: 0 0 .65px #333, 0 0 .65px #333;
            clip-path: inset(-15px -15px 0 -15px);
        }
        .header-dropdown-content {
            display: block;
        }
    }
    .header-dropdown-selection {
        cursor: pointer;
        display: flex;
        padding: 10px 15px;
        align-items: center;
        justify-content: center;
        background: #fff;
        transition: text-shadow 0.3s ease-in-out;
        &:hover {
            color: $secondary;
            // font-weight: bold;
            // text-shadow: 0 0 .65px #333, 0 0 .65px #333;
        }
    }
    .header-dropdown-content {
        border-top: 1px solid #f0f0f0;
        clip-path: inset(0px -15px -15px -15px);
        z-index: 900;
        background: #fff;
        position: absolute;
        display: none;
        top: calc(100% - 1px);
        width: 200px;
        overflow: hidden;
        left: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        &.right {
            left: auto;
            right: 0;
        }
        .header-dropdown-item {
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 5px;
            &:hover {
                // text-shadow: 0 0 .65px #333, 0 0 .65px #333;
                color: $secondary;
            }
        }
    }
}
