@import "variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family !important;
    -webkit-font-smoothing: antialiased;
}

.text-logo {
    text-transform: uppercase;
    letter-spacing: 2px;
}

.separator {
    border-bottom: 1px solid $border-color;
}

.fs-6 {
    font-size: $font-size-base * 0.8 !important;
}

.fs-7 {
    font-size: $font-size-base * 0.7 !important;
}

.fs-8 {
    font-size: $font-size-base * 0.6 !important;
}

.data-entry {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 5px;
    span {
        text-align: right;
    }
}

.bg-grey {
    background: #f0f0f0;
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 17px;
}

h5 {
    font-size: 15px;
}

h6 {
    font-size: 14px;
}

.text-primary {
    color: var(--platform-color) !important;
}
