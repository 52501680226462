.filter-modal {
    --width: 20%;
    --min-width: 350px;
    --height: 100%;
    --border-radius: 0;
    justify-content: flex-start;
}

.image-gallery-modal {
    --height: 100%;
    --width: 100%;
    --border-radius: 0;
}

ion-modal ion-content {
    --background: #fff;
}
