@import "variables";

.horizontal-menu {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow: auto;

    &.horizontal-menu-inline {
        display: inline-flex;
        max-width: 100%;
        padding: 0 20px;
    }

    &.horizontal-menu-no-border-bottom {
        border-bottom: 1px solid transparent;
    }

    &.horizontal-menu-no-border-top {
        border-top: 1px solid transparent;
    }

    &.horizontal-menu-left {
        justify-content: flex-start;
    }

    .horizontal-menu-item {
        display: inline-flex;
        height: 100%;

        & > span,
        & > a {
            color: #333;
            cursor: pointer;
            padding: 10px;
            letter-spacing: 0.03em;
            border-bottom: 2px solid transparent;
            display: block;
            height: 100%;
            min-width: 70px;
            text-align: center;
            white-space: nowrap;
        }

        &:hover > span,
        &.active > span,
        &:hover > a,
        &.active > a,
        & > a.active {
            color: var(--platform-color);
            font-weight: bold;
            //letter-spacing: .02em;
            //text-shadow: 0 0 .65px #00b5cc, 0 0 .65px #00b5cc;
            border-bottom: 2px solid var(--platform-color);
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}
