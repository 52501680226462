@import "variables";

.toast-success,
.toast-error {
    --width: 300px;
    &::part(container) {
        background: $success;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
    }
    &::part(icon) {
        fill: #fff;
    }
}

.toast-error {
    &::part(container) {
        background: $danger;
    }
}
