@import "variables";

ion-app {
    --background: #{$body-background};
}

ion-content {
    --background: #{$body-background};
}

ion-menu ion-content {
    --padding-top: calc(40px + var(--ion-safe-area-top, 0px));
    --padding-bottom: calc(20px + var(--ion-safe-area-bottom, 0px));
}

.content-wrapper {
    min-height: calc(100vh - 300px);
}

#app-wrapper {
    padding-top: var(--ion-safe-area-top, 0px);
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    app-header {
        width: 100%;
    }
    #app-inner {
        width: 100%;
        flex: 1;
        position: relative;
        overflow: hidden;
        min-height: calc(100vh - 200px);
        ion-content {
            --padding-top: 40px;
        }
    }
}

ion-modal {
    --ion-background-color: #{$body-background} !important;
}

ion-fab {
    bottom: 25px;
    right: 40px;
}

.bi {
    line-height: 1.2;
}

app-picture {
    position: relative;
    overflow: hidden;
}

// media query for mobile container padding
@media (max-width: 767px) {
    .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }
}

// Hide the footer on desktop
@media (min-width: 992px) {
    app-footer-mobile {
        display: none;
    }
}

// Add padding to the bottom of the page content on mobile
@media (max-width: 992px) {
    #main-content .ion-page ion-content {
        --padding-bottom: 20px;
    }
}

.inner-content {
    max-width: 800px;
    margin: 0 auto;
}
