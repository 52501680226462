/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@font-face {
    font-weight: 1 999;
    font-family: "Work Sans";
    src: url("public/assets/fonts/work-sans.ttf") format("truetype-variations");
}

@import "node_modules/swiper/swiper-bundle.css";

@import "intl-tel-input/build/css/intlTelInput.css";
@import "theme/variables";
@import "theme/ionic-variables";

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";

// Import custom Bootstrap styles
@import "bootstrap/dist/css/bootstrap.min.css";

@import "theme/platforms/marketplace";
@import "theme/platforms/wholesale";
@import "theme/platforms/gazette";

// Import Tabler Icons
@import "@tabler/icons-webfont/dist/tabler-icons.min.css";

@import "theme/links";
@import "theme/maps";
@import "theme/layout";
@import "theme/forms";
@import "theme/typography";
@import "theme/menu";
@import "theme/modals";
@import "theme/dropdown";
@import "theme/toasts";
@import "theme/helpers";
@import "theme/list";
@import "theme/accordion";
@import "theme/modal";
@import "theme/buttons";
@import "theme/table";
@import "theme/cards";

$input-border-width: 0px;
$border-radius: 10px;
@import "tom-select/dist/css/tom-select.bootstrap5.min.css";
