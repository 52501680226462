@import "variables";

.form-group {
    margin-bottom: 1rem;
}

label {
    font-weight: bold;
    font-family: $headings-font-family;
    &.required {
        &:after {
            content: "";
            width: 5px;
            height: 5px;
            margin-left: 3px;
            margin-bottom: 5px;
            background-color: #f00;
            display: inline-block;
            border-radius: 50%;
        }
    }
}

input.form-control,
textarea.form-control,
select.form-select {
    min-height: 40px;
    border: 1px solid $border-color;
    background: #fff;
    &:focus,
    &:active {
        background: #fafafa;
    }
}

select.form-select,
select.form-control {
    // Add caret icon after select
    position: relative;
    //&::before {
    //  content: " ";
    //  color: #000;
    //  position: absolute;
    //  right: 10px;
    //}
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC) !important;
    background-position: 100% !important;
    background-repeat: no-repeat !important;
    background-size: 25px 15px !important;
}

.create-offer {
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
}

.btn {
    transition: all 0.2s ease-in-out;
    &:focus {
        transform: scale(0.95);
    }
}

ion-checkbox {
    --border-color: #{$primary};
    --border-color-checked: #{$secondary};
    --border-radius: 5px;
    --border-width: 1px;
    --checkmark-color-: #fff;
    --checkbox-background-checked: #{$secondary};
    --checkmark-width: 2px;
}

.input-group-text {
    min-height: 40px;
    border: 1px solid $border-color;
    background: $border-color;
}
