@import "variables";

.map {
    overflow: auto;
    padding: 40px;
    display: inline-block;
    max-width: 1100px;
    width: 100%;
    svg {
        stroke: #ccc;
        fill: #fafafa;
        display: block;
        width: 100% !important;
        max-width: 1100px !important;
        height: auto !important;
        stroke-width: 1pt;
        path:hover {
            fill: $secondary;
            cursor: pointer;
        }
    }
}

// Change padding for mobile
@media (max-width: 767px) {
    .map {
        padding: 20px 0;
    }
}
