@import "variables";

.btn.change-picture {
    position: absolute;
    background-color: #fff;
    color: #333;
    box-shadow: $box-shadow;
    font-size: 14px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: #f0f0f0;
    }
}

.btn-gazette {
    background-color: $g-dark;
    color: #000;
    &:hover {
        background-color: darken($g-dark, 10%);
    }
}

.btn-marketplace {
    background-color: $m-dark;
    color: #fff;
    &:hover {
        background-color: darken($m-dark, 10%);
        color: #fff;
    }
}

.btn-wholesale {
    background-color: $w-dark;
    color: #fff;
    &:hover {
        background-color: darken($w-dark, 10%);
        color: #fff;
    }
}

.btn.btn-primary {
    border: none !important;
    box-shadow: none !important;
}

.btn.btn-outline-primary {
    border: 1px solid var(--platform-color);
    color: var(--platform-color);
    &:hover {
        background-color: var(--platform-color);
        color: #fff;
    }
}

.btn.btn-link-primary {
    color: var(--bs-primary);
    &:hover {
        color: var(--bs-primary-hover);
    }
}

.btn.btn-link-danger {
    color: var(--bs-danger);
    &:hover {
        color: var(--bs-danger-hover);
    }
}
