// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** @deprecated */
$primary: #383838;
/** @deprecated */
$secondary: #00a7be;
/** @deprecated */
$danger: #ce332a;
/** @deprecated */
$success: #2f9428;
$font-family-base: "Work Sans", sans-serif;
$headings-font-family: "Work Sans", sans-serif;
$headings-font-weight: 600;
$container-padding-x: 60px;
$border-color: #ddd;
$light-grey: #f0f0f0;
$box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$muted: #999;
$body-background: #f9f9f9;
$link-color: #383838;
$footer-color: #000;
// Platform colors
$m-light: #8aceed; // marketplace
$m-dark: #170295; // marketplace
$g-light: #dbdb25; // gazette
$g-dark: #d0bd2c; // gazette
$w-light: #f7cc72; // wholesale
$w-dark: #f1a75a; // wholesale

// Headers
$ion-header-background: #fff;
$ion-header-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

// Content
$ion-content-background: #fff;

// Font size
$font-size-base: 15px;
$nav-link-height: 40px;

// Cards
$card-border-width: 0;
$card-background: #fff;
$card-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
$card-border-radius: 10px;

// Modals
$modal-background: #fff;

// Forms
$input-btn-focus-box-shadow: none;
$input-border-radius: 10px;
$input-bg: #f0f0f0;
$input-border-width: 0;
$input-group-addon-bg: #f0f0f0;
$input-group-addon-color: #000;

// Button border radius
$btn-border-radius: 10px;
$btn-padding-y: 10px;

// Spacers
$spacers: (
    0: 0,
    1: 0.25rem,
    2: 0.5rem,
    3: 1rem,
    4: 1.5rem,
    5: 3rem,
    6: 4rem,
    7: 5rem,
);

// Container
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1600px,
);

// Common boostrap var
:root {
    --bs-body-font-size: #{$font-size-base};
    --bs-body-font-family: "Work Sans", sans-serif;
    --bs-headings-font-family: "Work Sans", sans-serif;
}

:root {
    --bs-danger: #{$danger};
    --bs-danger-hover: #{darken($danger, 10%)};
    --bq-accordion-border-radius: 2px;
}
