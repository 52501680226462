@import "variables";

a,
.btn-link {
    text-decoration: none;
    font-weight: 600;
    color: $link-color;
    &:hover {
        text-decoration: none;
        color: var(--bs-primary);
    }
}

.btn-link {
    border-radius: 0;
    border-bottom: 1px solid $border-color;
    &:hover {
        border-bottom: 1px solid $primary;
    }
}
