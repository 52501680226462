.card-list {
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
    li {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-top: 10px;
        padding: 10px 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .bi {
            font-size: 15px;
            padding-left: 10px;
            display: inline-flex;
            align-items: center;
        }
    }
}

.order-status {
    font-weight: bold;
}
